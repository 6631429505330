import React, { Component, useEffect } from 'react'
import HeroTwo from './Hero/HeroTwo';
import DomainOffer from './Features/DomainOffer';
import DomainData from './Features/DomainData';
import DomainBox from './Features/DomainBox';
import ServiceTwo from './Services/ServiceTwo';
import ServiceData from './Services/ServiceData';
import PriceTwo from './Price/PriceTwo';
import Map from './Map';
import WhyUs from './WhyUs';
import FaqData from './Faq/FaqData';
import FaqTwo from './Faq/FaqTwo';
import Team from './Team';
import TeamData from './Data/TeamData';
import TestimonialData from './Testimonial/TestimonialData';
import Testimonial from './Testimonial/Testimonial';
import ContactOne from './Contact/ContactOne';
import CpanelHosting from './Services/cPanelHosting';
import VPS from './Services/vps';
import DS from './Services/DedicatedServer';
import SSL from './Services/SSLCertificate';
import ContactTwo from './Contact/ContactTwo';
import Client from './Client';
import TechPartner from './TechParner';
import { DiscountOffer } from './discount_offers/DiscountOffer';

// export class HomeTwo extends Component {

const HomeTwo = () => {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <React.Fragment>
     <div
  style={{
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,.9)",
    zIndex: 999999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    textAlign: "center",
    padding: "20px"
  }}
>
  <div>
    <h3 className='text-white'>This website is currently under development.</h3>
    <h5 className='text-white'>If you need any support, please contact us at <a href="mailto:support@dhakacloud.cloud" style={{ color: "white", textDecoration: "underline" }}>support@dhakacloud.cloud</a>.</h5>
  </div>
</div>

{/* <DiscountOffer /> */}
      <HeroTwo />
      <WhyUs />
      {/* <ServiceTwo ServiceData={ServiceData} /> */}
      {/* <CpanelHosting /> */}
      {/* <VPS /> */}
      {/* <DS /> */}
      {/* <SSL /> */}
      {/* <PriceTwo/> */}
      {/* <DomainOffer/> */}
      {/* <FaqTwo FaqData={FaqData} /> */}
      <Testimonial TestimonialData={TestimonialData} />
      <Client />
      <Map />
      <TechPartner />
      <FaqTwo FaqData={FaqData} />
      {/* <Team TeamData={TeamData} />
                <Testimonial TestimonialData={TestimonialData} /> */}
      {/* <Testimonial TestimonialData={TestimonialData} /> */}
      <ContactOne />
      {/* <ContactTwo/> */}

    </React.Fragment>
  )
}

export default HomeTwo
